<template>
  <div class="m-3 bg-white rounded-xl h-detail w-full text-gray-900 fw-500">
    <div class="fs-20 fw-600 text-grey-800 py-3 px-4">HODO Member</div>
    <div class="flex justify-between items-center">
      <div class="flex ml-4">
        <div class="flex items-center justify-center border rounded-md border-grey-300 p-2 mr-2 w-64">
          <IconSearch class="cursor-pointer" />
          <input
            class="border-0 w-64 ml-2 focus:outline-none"
            placeholder="Tên, Gói thành viên"
            v-model="searchValue"
            @keypress.enter="searchMember"
          >
        </div>
        <div class="ml-2">
          <select
            name=""
            id=""
            v-model="has_option"
            class="p-2.5 border-grey-400 rounded-lg"
            @change="filterOption()"
          >
            <option :value="0">Lọc theo</option>
            <option :value="1">Tháng này</option>
            <option :value="2">Quý này</option>
            <option :value="3">Năm nay</option>
          </select>
        </div>
      </div>

      <div class="flex mr-4">
        <div class="flex items-center fw-600">
          Từ ngày
          <div class="fs-14 fw-500 txt-grey-900 ml-3"><input
              v-model="startTime"
              type="date"
              name="bd"
              id="bd"
              placeholder="Chọn ngày"
              class="form--input fs-16 fw-500"
            /></div>
        </div>
        <div class="flex items-center ml-3 fw-600">
          Đến ngày
          <div class="fs-14 fw-500 txt-grey-900 ml-3"><input
              v-model="endTime"
              type="date"
              name="bd"
              id="bd"
              placeholder="Chọn ngày"
              class="form--input fs-16 fw-500"
            /></div>
        </div>

        <div
          class="h-10 bg-blue-700 text-white fs-16 fw-600 px-6 rounded-lg flex items-center ml-3 cursor-pointer"
          @click="filterDate()"
        >Tìm</div>
        <div
          class="h-10 bg-blue-700 text-white fs-16 fw-600 px-6 rounded-lg flex items-center ml-3 cursor-pointer"
          @click="exportExcel()"
        >
          Export</div>
      </div>
    </div>

    <div class="my-3 mx-4">
      <div class="row py-3">
        <div class="col-lg-2">Tên</div>
        <div class="col-lg-2">Thời gian mua</div>
        <div class="col-lg-2">Gói Thành viên</div>
        <div class="col-lg-1">SKU </div>
        <div class="col-lg-2">Store </div>
        <div class="col-lg-2">Trạng thái</div>
        <div class="col-lg-1 text-center">Thành tiền</div>
      </div>
      <div
        class="row border-t-1 py-3"
        v-for="(item, index) in listMember"
        :key="index"
      >
        <div
          class="col-lg-2"
          v-if="item.user && item.user_id"
        >
          <div class="fs-16 fw-600">{{item.user && item.user.name}}</div>
          <div class="fs-12">{{item.user_id && 'User ID: ' + item.user_id}}</div>
        </div>
        <div
          class="col-lg-2"
          v-else
        >
          missing
        </div>
        <div class="col-lg-2">{{ item.sub_start_time_ms && getDate(item.sub_start_time_ms) }}</div>
        <div class="col-lg-2">{{ item.package && item.package.name}}</div>
        <div class="col-lg-1">{{ getSKU(item.subscription_id) }} </div>
        <div class="col-lg-2">
          <div>{{item.appstore_name}}</div>
          <div>{{item.sub_expiry_time_ms && getDate(item.sub_expiry_time_ms)}}</div>
        </div>
        <div class="col-lg-2">{{ getStatus(item) }} </div>
        <div class="col-lg-1 text-center">{{formatVND(item.price ? item.price : (item.package && item.package.price_per_month))}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { IconSearch } from '@/components/Icon'
import { writeFile, utils } from 'xlsx'

export default {
  name: 'HodoMember',
  components: { IconSearch, vSelect },
  data () {
    return {
      searchValue: '',
      has_option: 0,
      startTime: window.moment().subtract(3, 'd').format('YYYY-MM-DD'),
      endTime: window.moment(new Date()).format('YYYY-MM-DD'),
      listMember: [],
      in_date_range: null
    }
  },
  mounted () {
    this.getHodoMember()
  },
  methods: {
    getHodoMember () {
      const params2 = {}
      if (this.startTime && this.endTime) {
        params2.from = window.moment(this.startTime).toDate()
        params2.to = window.moment(this.endTime).toDate()
      } else if (this.in_date_range) {
        params2.in_date_range = this.in_date_range
      }
      params2.search = this.searchValue
      this.$rf.getRequest('AdminRequest').getHodoMember(params2).then((res) => {
        this.listMember = res.data.data
      }).finally(() => {
        // self.loading = false
      })
    },
    searchMember () {
      this.getHodoMember()
    },
    getDate (miliseconds) {
      return window.moment(miliseconds, 'x').format('HH:mm DD/MM/YYYY')
    },
    filterOption () {
      if (this.has_option === 1) {
        this.in_date_range = 'month'
      } else if (this.has_option === 2) {
        this.in_date_range = 'quarter'
      } else if (this.has_option === 3) {
        this.in_date_range = 'year'
      } else this.in_date_range = null
      this.startTime = ''
      this.endTime = ''
      this.getHodoMember()
    },
    filterDate () {
      if (this.startTime && this.endTime) {
        this.has_option = 0
        this.in_date_range = null
        this.getHodoMember()
      } else {
        alert('Vui lòng chọn ngày để lọc')
      }
    },
    exportExcel () {
      const list = this.listMember
      const result = list.map(item => {
        return {
          'Tên': item.user && item.user.name,
          'User_ID': item.user_id,
          'Thời gian mua': this.getDate(item.sub_start_time_ms),
          'Gói Thành viên': item.package && item.package.name,
          'SKU': item.subscription_id,
          'Store': item.appstore_name,
          'Thời gian hết hạn': this.getDate(item.sub_expiry_time_ms),
          'Thành tiền': item.price ? item.price : (item.package && item.package.price_per_month)
        }
      })
      this.convertJsonToExcel(result)
    },
    async convertJsonToExcel (result) {
      const workSheet = utils.json_to_sheet(result)
      const workBook = utils.book_new()

      utils.book_append_sheet(workBook, workSheet, 'Data')

      writeFile(workBook, `Hodo_Member_${window.moment().format('DD/MM/YYYY')}.xlsx`)
    },
    formatVND (price) {
      if (!price) return 0
      const number = Number(price)
      return number.toFixed().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
    },
    getSKU (str) {
      if (!str) return ''
      const array = str.split('.')
      return array && array[array.length - 1]
    },
    getStatus (item) {
      if (!item) return ''
      if (item.is_refunded) return 'Hoàn tiền'
      if (item.is_subscription) {
        if (item.is_subscription_retry_period) return 'Đang trong thời gian thử lại đăng ký'
        if (item.is_subscription_renew) return 'Gia hạn'
        return 'Đăng ký mới'
      }
      if (item.voucher) return 'Sử dụng voucher'
      return ''
    }
  }
}
</script>

<style scoped>
.h-detail {
  height: calc(100% - 40px);
}

.border-t-1 {
  border-top: 1px solid #efefef;
}
</style>