<template>
	<div class="row">
		<div class="telemedicine-management pr-3">
			<div class="row pl-3">
				<div class="col-12">
					<div class="d-flex py-1 align-items-center">
						<img class="mr-2" src="https://img.icons8.com/windows/50/0C53FB/how-many-quest.png"/><span class="txt-pri page-title">Nhóm câu hỏi</span>
					</div>
				</div>
				<div class="col-sm-12">
					<div class="row justify-content-lg-between">
						<div class="col-12 col-lg-auto mb-1">
							<div class="d-flex">
							</div>
						</div>
						<div class="col-12 col-lg-auto mb-1">
							<div class="d-flex">
								<!-- <button class="btn bg-pri bd-pri text-white btn-icon-add-sm" @click="addPermisions()">Thêm câu hỏi</button> -->
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card radius-10 pt-4 pl-4 pr-4 border-0">
				<div class="table-responsive">
					<table class="table table-vcenter table-mobile-md card-table table-scroll">
						<thead>
							<tr>
								<th>Tên nhóm câu hỏi</th>
								<th>Thời gian tạo</th>
							</tr>
						</thead>
						<tbody v-if="questions && questions.count && questions.data" style="min-height: 600px;">
							<tr v-for="question in questions.data" :key="question.id">
								<td>{{question ? question.name ? question.name : '' : ''}}</td>
								<td v-html="question ? question.created_at ? formatWeekdayTime(question.created_at) : '' : ''"></td>
							</tr>
						</tbody>
					</table>
					<Pagination v-show="questions && questions.count" v-if="questions" :items="questions" :current_page="current_page" @onRefresh="getGroupQuestion"></Pagination>
				</div>
				<div v-if="questions && !questions.count && !loading" class="row">
					<div class="col-sm-4 offset-sm-4 pt-3 pb-5 text-center">
						<img src="../../../public/assets/images/not-found-search.png" class="img-responsive p-0" alt="">
						<p class="robo-16-400 text-black">{{search ? 'HODO không tìm thấy bệnh nhân mà bạn tìm' : 'Chưa có bệnh nhân nào!' }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
  import Pagination from '../../components/Pagination'
  export default {
    name: 'GroupQuestion',
    components: {Pagination},
    data () {
      return {
        questions: null,
        loading: false,
        params: {
          limit: 10,
          search: '',
          sort: 'created_at',
          sort_by: 'desc'
        },
        search: '',
        current_page: 0,
        page: 1
      }
    },
    mounted () {
      this.page = this.$route.query.page ? this.$route.query.page : 1
      this.getGroupQuestion()
    },
    watch: {
      '$route.query.type': {
        handler: function () {
          let self = this
          self.page = self.$route.query.page
          self.getGroupQuestion()
        },
        deep: true
      }
    },
    methods: {
      async getGroupQuestion () {
        try {
          let self = this
          self.current_page = parseInt(self.$route.query.page) || 1
          self.params = {
            limit: 10,
            search: self.search,
            sort: 'created_at',
            sort_by: 'desc',
            page: self.current_page
          }
          const result = await self.$rf.getRequest('AdminRequest').getQuestionGroups(self.params)
          self.questions = result.data
          self.current_page = result.data.current_page
        } catch (e) {
          console.log(e)
        } finally {

        }
      },
      formatWeekdayTime (date_time) {
        var split_time = window.moment(date_time).format('DD/MM/YYYY HH:mm ').split(' ')
        var str_time = split_time[1] + '<br><small class="robo-12-400 text-muted">' + split_time[0] + '</small>'
        return str_time
      }
    }
  }
</script>
<style scoped></style>