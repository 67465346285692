<template>
  <div class="container pt-5">
    <div class="d-flex align-items-end mb-3">
      <a href="javascript:;" :class="showing == 'k' ? 'fs-24 fw-600' : 'fs-18'" class="txt-pri mr-3 text-decoration-underline" @click="backToShops('k')">Danh sách từ khóa</a>
      <a href="javascript:;" :class="showing == 'v' ? 'fs-24 fw-600' : 'fs-18'" class="txt-pri text-decoration-underline" @click="backToShops('v')">Danh sách vi phạm</a>
    </div>
    <div v-show="add">
      <p class="fs-24 fw-600">Thêm từ khóa</p>
      <div class="mb-3">
        <label class="form-label required">Từ khóa</label>
        <input
          type="text"
          class="form-control border"
          name="example-required-input"
          placeholder="Required..."
        />
      </div>
      <div class="mb-3">
        <label class="form-label required">Đơn giá</label>
        <select>
          <option value="">Ban</option>
          <option value="">Check</option>
        </select>
      </div>
      <div class="mb-3">
        <label class="form-label required">Ghi chú</label>
        <textarea class="form-control"></textarea>
      </div>
    </div>
    <div v-show="showing == 'k'">
      <div class="card">
        <div class="table-responsive">
          <table class="table table-vcenter card-table">
            <thead>
              <tr>
                <th class="pl-3">Từ khóa</th>
                <th>Hành động</th>
                <th>Ghi chú</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="p in keywords" :key="p.name">
                <td class="pl-3" data-label="Từ khóa">
                   {{ p.keyword }}
                </td>
                <td data-label="Hành động">
                  {{p.action}}
                </td>
                <td data-label="Ghi chú">
                  {{p.note}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        class="card-footer d-flex justify-content-between align-items-center"
      >
        <p class="m-0 text-muted">
          Showing <span>1</span> to <span>4</span> of <span>16</span> entries
        </p>
        <ul class="pagination m-0 ms-auto">
          <li class="page-item disabled">
            <a class="page-link" href="#" tabindex="-1" aria-disabled="true">
              <!-- Download SVG icon from http://tabler-icons.io/i/chevron-left -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <polyline points="15 6 9 12 15 18"></polyline>
              </svg>
              prev
            </a>
          </li>
          <li class="page-item active"><a class="page-link" href="#">1</a></li>
          <li class="page-item"><a class="page-link" href="#">2</a></li>
          <li class="page-item"><a class="page-link" href="#">3</a></li>
          <li class="page-item"><a class="page-link" href="#">4</a></li>
          <li class="page-item"><a class="page-link" href="#">5</a></li>
          <li class="page-item">
            <a class="page-link" href="#">
              next
              <!-- Download SVG icon from http://tabler-icons.io/i/chevron-right -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <polyline points="9 6 15 12 9 18"></polyline>
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div v-show="showing == 'v'">
      <div class="card">
        <div class="table-responsive">
          <table class="table table-vcenter card-table table-bordered">
            <thead>
              <tr>
                <th class="pl-3">Tên sản phẩm</th>
                <th>Vi phạm</th>
                <th class="w-1">Hành động</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="s in violations"
                :key="s.name"
                class="cursor-pointer"
              >
                <td class="pl-3">
                  <div class="d-flex py-1 align-items-center">
                    <div class="flex-fill">
                      <div class="font-weight-medium max-line-1">
                        {{ s.name }}
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="text-muted max-line-1">{{ s.violation }}</div>
                </td>
                <td>
                  <!-- <a href="#">Xóa</a> -->

                  <button class="btn border bg-white txt-err-800 bd-err-800 radius-10 w-90px mb-1">
                    Xóa
                  </button>
                  <button class="btn border bg-white txt-err-800 bd-err-800 radius-10 w-90px">
                    Chặn
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        class="card-footer d-flex justify-content-between align-items-center"
      >
        <p class="m-0 text-muted">
          Showing <span>1</span> to <span>4</span> of <span>16</span> entries
        </p>
        <ul class="pagination m-0 ms-auto">
          <li class="page-item disabled">
            <a class="page-link" href="#" tabindex="-1" aria-disabled="true">
              <!-- Download SVG icon from http://tabler-icons.io/i/chevron-left -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <polyline points="15 6 9 12 15 18"></polyline>
              </svg>
              prev
            </a>
          </li>
          <li class="page-item active"><a class="page-link" href="#">1</a></li>
          <li class="page-item"><a class="page-link" href="#">2</a></li>
          <li class="page-item"><a class="page-link" href="#">3</a></li>
          <li class="page-item"><a class="page-link" href="#">4</a></li>
          <li class="page-item"><a class="page-link" href="#">5</a></li>
          <li class="page-item">
            <a class="page-link" href="#">
              next
              <!-- Download SVG icon from http://tabler-icons.io/i/chevron-right -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <polyline points="9 6 15 12 9 18"></polyline>
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KeyManage',
  data () {
    return {
      img: '',
      add: false,
      list: false,
      keywords: [
        { keyword: 'Ma túy', action: 'ban', note: 'Cấm' },
        { keyword: 'Thuốc lá', action: 'ban', note: 'Cấm' },
        { keyword: 'Covid', action: 'check', note: 'Kiểm tra' },
        { keyword: 'Kit test', action: 'check', note: 'Kiểm tra' },
        { keyword: 'Thảo dược', action: 'check', note: 'Kiểm tra' },
        { keyword: 'Ung thư', action: 'check', note: 'Kiểm tra' },
        { keyword: 'Súng', action: 'ban', note: '' }
      ],
      violations: [
        { name: 'Thực phẩm hỗ trợ bệnh nhân ung thư', violation: 'ung thư' },
        { name: 'Kit test covid Biosynex', violation: 'Kit test, covid' },
        { name: 'Kit test covid Humasis ', violation: 'Kit test, covid' },
        { name: 'Kit test covid GenBody ', violation: 'Kit test, covid' },
        { name: 'Kit test covid SGTi-flex ', violation: 'Kit test, covid' },
        { name: 'Kit test covid BioCredit  ', violation: 'Kit test, covid' },
        { name: 'Trà thảo dược hoa cúc THC', violation: 'thảo dược' },
        { name: 'Trà thảo dược bạc hà THC', violation: 'thảo dược' },
        { name: 'Trà gừng thảo dược THC', violation: 'thảo dược' },
        { name: 'Thuốc ho Prospan - Siro ho lá thường xuân ', violation: 'Thuốc lá' },
        { name: 'Thực phẩm hỗ trợ hậu covid', violation: 'covid' }
      ],
      showing: 'k'
    }
  },
  methods: {
    backToShops (k) {
      this.showing = k
    }
  }
}
</script>

<style scoped>
.w-90px {
  width: 90px;
}
</style>