<template>
  <div class="row">
    <div class="telemedicine-management pr-3">
      <div class="row pl-3">
        <div class="col-12">
          <div class="d-flex py-1 align-items-center">
            <img src="../../../public/assets/images/icon/invoice.svg" alt="" class="mr-2"><span
              class="txt-pri page-title">Hoá đơn</span>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="row">
            <div class="col-12 col-lg-6 mb-1">
              <div class="row">
                <div class="col-auto">
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button type="button" class="btn h-100" :class="type === 1 ? 'btn--blue' : 'btn--babyblue'"
                      @click="changeType(1)">Hôm nay</button>
                    <button type="button" class="btn h-100" :class="type === 2 ? 'btn--blue' : 'btn--babyblue'"
                      @click="changeType(2)">Tuần</button>
                    <button type="button" class="btn h-100" :class="type === 3 ? 'btn--blue' : 'btn--babyblue'"
                      @click="changeType(3)">Tháng</button>
                    <button type="button" class="btn h-100" :class="type === 4 ? 'btn--blue' : 'btn--babyblue'"
                      @click="changeType(4)">Quý</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 mb-1">
              <div align="right" class="row">
                <div class="col-sm-4">
                  <div class="dropdown">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      <div>
                        <div><small class="text-primary">Trạng thái</small></div>
                        <div>
                          <span v-if="status === 0">Tất cả</span>
                          <span v-if="status === 1">Chưa thanh toán</span>
                          <span v-if="status === 2">Xác nhận</span>
                          <span v-if="status === 3">Chờ xác nhận</span>
                        </div>
                      </div>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a class="dropdown-item" href="javascript:void(0)" @click="selectStatus(0)">Tất cả</a>
                      <a class="dropdown-item" href="javascript:void(0)" @click="selectStatus(1)">Chưa thanh toán</a>
                      <a class="dropdown-item" href="javascript:void(0)" @click="selectStatus(2)">Xác nhận</a>
                      <a class="dropdown-item" href="javascript:void(0)" @click="selectStatus(3)">Chờ xác nhận</a>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <input type="text" class="form-control h-100 bg-white" placeholder="Nhập từ khoá tìm kiếm"
                    v-model="search">
                </div>
                <div class="col-sm-4">
                  <button class="btn bg-pri bd-pri text-white h-100 w-100" style="font-size: 18px; font-weight: 700;"
                    @click="filterRequest()">Lọc dữ liệu</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card radius-10 pt-2 pl-4 pr-4 border-0">
        <div class="table-responsive">
          <table class="table table-vcenter table-mobile-md card-table table-scroll">
            <thead>
              <tr>
                <th>Thông tin bệnh nhân</th>
                <th>Thời gian gửi</th>
                <th>Tới bác sĩ</th>
                <th>Tới bệnh nhân</th>
                <th>Mã hoá đơn</th>
                <th>Trạng thái</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody v-if="invoices && invoices.count && invoices.data"
              :class="invoices && invoices.count >= 9 ? 'h-100' : ''">
              <tr v-for="(invoice, index) in invoices.data" :key="index" class="table-tr mt-2">
                <td data-label="Thông tin bệnh nhân" class="table-td-name-avatar">
                  <div class="d-flex py-1 align-items-center">
                    <div class="ml-1" style="min-width: 40px;">
                      <span v-if="hasAvatar(invoice)" class="avatar avatar-sm avatar-rounded"
                        :style="'background-image: url(' + getImageURL(invoice.user.avatar) + ')'"></span>
                      <span v-if="!hasAvatar(invoice)" class="avatar avatar-sm avatar-rounded bg-secondary text-white">
                        {{ invoice && invoice.user && invoice.user.name ? invoice.user.name.charAt(0) : '' }}
                      </span>
                    </div>
                    <div class="flex-fill">
                      <p class="p-0 m-0">{{ invoice && invoice.user && invoice.user.name ? invoice.user.name : '' }}</p>
                      <small class="text-black-50" v-if="invoice && invoice.appt && invoice.appt.created_by == 1">{{
                        invoice && invoice.doctor &&
                        invoice.doctor.phone ? invoice.doctor.phone : '' }}</small>
                      <div v-if="invoice && invoice.appt && invoice.appt.created_by == 2">{{ invoice && invoice.appt &&
                        invoice.appt.person && invoice.appt.person.name }}</div>
                      <small class="text-black-50" v-if="invoice && invoice.appt && invoice.appt.created_by == 2">{{
                        invoice && invoice.user &&
                        invoice.user.username ? invoice.user.username : '' }}</small>
                    </div>
                  </div>
                </td>
                <td data-label="Thời gian gửi" v-html="">
                  <div class="d-flex py-1 align-items-center">
                    <div class="ml-1" style="min-width: 40px;" v-if="invoice && invoice.appt">
                      <span class="avatar avatar-sm avatar-rounded bg--primary icon-video"></span>
                    </div>
                    <div class="flex-fill">
                      <p class="p-0 m-0"
                        v-html="invoice && invoice.appt && invoice.appt.created_at ? formatWeekdayTime(invoice.appt.created_at) : ''">
                      </p>
                    </div>
                  </div>
                </td>
                <td data-label="Tới bác sĩ">
                  <div v-if="invoice && invoice.appt && invoice.appt.created_by == 2">{{ invoice && invoice.doctor &&
                    invoice.doctor.name }}</div>
                </td>
                <td data-label="Tới bệnh nhân">
                  <div v-if="invoice && invoice.appt && invoice.appt.created_by == 1">{{ invoice && invoice.appt &&
                    invoice.appt.person && invoice.appt.person.name }}</div>
                  <small class="text-black-50" v-if="invoice && invoice.appt && invoice.appt.created_by == 1">{{ invoice
                    &&
                    invoice.appt && invoice.appt.person && invoice.appt.person.phone }}</small>
                </td>
                <td data-label="Mã hoá đơn">
                  {{ invoice && invoice.code ? invoice.code : '' }}
                </td>
                <td data-label="Trạng thái">
                  <span style="border-radius: 5px;"
                    :class="invoice && invoice.status ? getInvoiceStatus(invoice.status).class : ''">
                    {{ invoice && invoice.status ? getInvoiceStatus(invoice.status).text : '' }}
                  </span>
                </td>
                <td data-label="Action" class="text-nowrap text-truncate">
                  <!-- <div v-if="invoice && invoice.appt && invoice.appt.created_by && invoice.appt.created_by == 2 ? true : false"> -->
                  <div>
                    <div class="col-sm-6 mt-1"><button class="btn btn-sm bg-pri bd-pri text-white"
                        @click="onAccept(invoice.id)" :disabled="invoice.status == 3 ? true : false">Xác nhận</button>
                    </div>
                    <div class="col-sm-6 mt-1" v-if="invoice && invoice.verify_photo"><button
                        class="btn btn-sm bg-pri bd-pri text-white" @click="showStatement(invoice.verify_photo)">Kiểm tra
                        sao kê</button></div>
                    <div class="col-sm-6 mt-1"><button class="btn btn-sm bg-pri bd-pri text-white"
                        @click="onNotifyUnpaid(invoice.id)" :disabled="invoice.status == 3 ? true : false">Nhắc thanh
                        toán</button></div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <Pagination v-show="invoices && invoices.count" v-if="invoices" :items="invoices" :current_page="current_page"
            @onRefresh="getInvoices"></Pagination>
        </div>
        <div v-if="invoices && !invoices.count" class="row">
          <div class="col-sm-4 offset-sm-4 pt-3 pb-5 text-center">
            <img src="../../../public/assets/images/not-found-search.png" class="img-responsive p-0" alt="">
            <p class="robo-16-400 text-black">Không có yêu cầu nào</p>
          </div>
        </div>
        <div class="modal" tabindex="-1" role="dialog" id="modal-display-statement">
          <div class="modal-dialog" role="document">
            <BankStatements :url_statements="verify_photo" @showCreate="showCreate"></BankStatements>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import appUtils from '../../utils/appUtils'
import Pagination from '../../components/Pagination'
import BankStatements from '../../components/BankStatements'
export default {
  name: 'InvoiceListing',
  components: { Pagination, BankStatements },
  data () {
    return {
      invoices: null,
      current_page: 1,
      search: '',
      params: {
        limit: 10,
        page: 1,
        start_time: '',
        end_time: ''
      },
      type: 1,
      status: 0,
      verify_photo: null
    }
  },
  mounted () {
    let self = this
    self.getInvoices()
  },
  methods: {
    async getInvoices () {
      let self = this
      self.current_page = parseInt(self.$route.query.page) || 1
      self.params.page = self.current_page
      self.params.sort = 'created_at'
      self.params.sort_by = 'desc'
      // self.params.created_by = 2
      self.params.search = self.search
      if (self.type === 1) {
        self.params.start_time = window.moment(new Date()).startOf('day').format('HH:mm DD-MM-YYYY')
        self.params.end_time = window.moment(new Date()).endOf('day').format('HH:mm DD-MM-YYYY')
      } else if (self.type === 2) {
        self.params.start_time = window.moment(new Date()).startOf('week').format('HH:mm DD-MM-YYYY')
        self.params.end_time = window.moment(new Date()).endOf('week').format('HH:mm DD-MM-YYYY')
      } else if (self.type === 3) {
        self.params.start_time = window.moment(new Date()).startOf('month').format('HH:mm DD-MM-YYYY')
        self.params.end_time = window.moment(new Date()).endOf('month').format('HH:mm DD-MM-YYYY')
      } else if (self.type === 4) {
        self.params.start_time = window.moment(new Date()).startOf('quarter').format('HH:mm DD-MM-YYYY')
        self.params.end_time = window.moment(new Date()).endOf('quarter').format('HH:mm DD-MM-YYYY')
      }
      if (self.status !== 0) {
        if (self.status === 1) {
          self.params.statuses = [1]
        } else if (self.status === 2) {
          self.params.statuses = [3]
        } else if (self.status === 3) {
          self.params.statuses = [2]
        }
      } else {
        self.params.statuses = ''
      }
      let resp = await self.$rf.getRequest('AdminRequest').getInvoiceListing(self.params)
      self.invoices = resp.data
    },
    onAccept (id) {
      let self = this
      try {
        var r = confirm('Bạn có chắc chắn xác nhận hoá đơn này?')
        if (r) {
          var params = {
            status: ''
          }
          self.$rf.getRequest('AdminRequest').postInvoicesStatus(id, params).then(res => {
            self.getInvoices()
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    onNotifyUnpaid (id) {
      let self = this
      try {
        var r = confirm('Bạn có chắc chắn xác nhận hoá đơn này?')
        if (r) {
          self.$rf.getRequest('AdminRequest').postInvoiceNotifyUnpaid(id).then(res => {
            self.getInvoices()
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    showCreate (show) {
      this.displayStatement(show)
    },
    showStatement (url) {
      this.verify_photo = url
      this.displayStatement(true)
    },
    displayStatement (show) {
      window.$('#modal-display-statement').modal(show ? 'show' : 'hide')
    },
    hasAvatar (invoice) {
      return invoice && invoice.user && invoice.user.avatar
    },
    getImageURL (avatar) {
      return appUtils.getImageURL(avatar)
    },
    selectStatus (type) {
      this.status = type
    },
    filterRequest () {
      let self = this
      self.$router.replace({ query: { page: 1 } }).catch(() => { })
      this.getInvoices()
    },
    formatWeekdayTimev2 (date_time) {
      let curr_week = window.moment().week()
      let date_time_week = window.moment(date_time).week()
      if (curr_week === date_time_week) {
        return window.moment(date_time).fromNow()
      } else {
        var split_time = window.moment(date_time).format('DD/MM/YYYY HH:mm ').split(' ')
        var str_time = split_time[1] + '<br><small class="robo-12-400 text-muted">' + split_time[0] + '</small>'
        return str_time
      }
    },
    formatTime (date_time) {
      var split_time = window.moment(date_time).format('HH:mm')
      return split_time
    },
    formatWeekdayTime (date_time) {
      var split_time = window.moment(date_time).format('DD/MM/YYYY HH:mm ').split(' ')
      var str_time = split_time[1] + '<br><small class="robo-12-400 text-muted">' + split_time[0] + '</small>'
      return str_time
    },
    getInvoiceStatus (stt) {
      let statuses = {}
      statuses[1] = { text: 'Chưa thanh toán', class: 'text--red' }
      statuses[2] = { text: 'Chờ xác nhận', class: 'text--yellow' }
      statuses[3] = { text: 'Xác nhận', class: 'text--green' }
      return stt && statuses[stt] ? statuses[stt] : statuses[1]
    },
    checkApptNow (request) {
      let self = this
      if (request) {
        let datetime_now = new Date()
        if (request.appointment) {
          return self.formatTimeDDMMYYYY(request.appointment.end_time) >= self.formatTimeDDMMYYYY(datetime_now)
        } else {
          return false
        }
      } else {
        return false
      }
    },
    checkStatusRequest (request) {
      let self = this
      if (request) {
        let datetime_now = new Date()
        if (request.appt_pending) {
          if (self.formatTimeDDMMYYYY(request.appt_pending.end_time) >= self.formatTimeDDMMYYYY(datetime_now)) {
            return 8
          } else {
            return self.checkStatusRequestV2(request)
          }
        } else {
          return self.checkStatusRequestV2(request)
        }
      } else {
        return 1
      }
    },
    formatTimeDDMMYYYY (datetime) {
      return window.moment(datetime).format('HH:mm DD-MM-YYYY')
    },
    formatTimeDDMMYYYYver2 (datetime) {
      return window.moment(datetime).format('DD-MM-YYYY HH:mm')
    },
    changeType (type) {
      this.type = type
      if (this.$route.path === '/admin/invoice') {
        this.$router.replace({ query: { page: 1 } }).catch(() => { })
        this.$router.push({ path: '/admin/invoice' }).catch(() => { })
      }
      this.getInvoices()
    }
  }
}
</script>
<style scoped>
.icon-video {
  background-image: url('../../../public/assets/images/icon/white_video.svg');
  background-repeat: no-repeat;
  background-size: 25px 25px;
  background-color: #20419B;
  background-origin: center;
  background-position: center;
  height: 36px;
  width: 36px;
}</style>